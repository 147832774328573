/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var renton = $('.renton'),
      southcenter = $('.southcenter'),
      seattle = $('.seattle');

  $(window).on('load', function (e) {
    southcenter.hide();
    seattle.hide();
  });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

      var mainMenuLinks = $('.main-nav > li > a');

      mainMenuLinks.on('click', function (e) {
        if ($(this).attr('href') === '#') {
          e.preventDefault();
        }
      });

      var menuList, mobileToggle;
      mobileToggle = $('.mobile_nav_toggle a');
      menuList = $('.main-nav');
      mobileToggle.on('click', function(e) {
        e.preventDefault();
        menuList.slideToggle();
      });

      function calloutSizer() {
        enquire
        .register("(max-width:1023px)", function() {
          $("#callout1").hide();
          $(".mobile-doctor-links").show();
        })
        .register("(min-width:1024px)", {
          match : function() {
            $(".mobile-doctor-links").hide();
            $("#callout1").show().hover(
              function() {
              $(".callout__doctor-links").stop(true).animate({
                  width: "toggle"
              }) },
              function() {
                $(".callout__doctor-links").stop(true).animate({
                  width: "toggle"
              })
            });
          },
          unmatch : function() {
            $("#callout1").hide();
            $(".mobile-doctor-links").show();
          }
        });
      };

      var id;
      function doneResizing(){
        calloutSizer(); 
      };

      $(window).resize(function() {
        clearTimeout(id);
        id = setTimeout(doneResizing, 500);
      });

      calloutSizer();
      

      var mobileTabs = $('.mobile_maps_tabs li a'),
          locationInfo = $('.location_info');
      mobileTabs.on('click', function (e) {
        e.preventDefault();
        locationInfo.slideUp();
        if ($(this).next().is(':visible')) {
          $(this).next().slideUp();
        } else {
          $(this).next().slideDown();
        }
      });

      var rentonBox = renton,
          southcenterBox = southcenter,
          seattleBox = seattle,
          maps = $('.maps > div'),
          mapTabs = $('.maps_tabs > li > a'),
          mapListing = $('.maps_tabs > li');
      
      mapListing.first().addClass('location_active');
      
      mapTabs.on('click', function (e) {
        e.preventDefault();
        mapTabs.parent().removeClass('location_active');
        $(this).parent().addClass('location_active');

        maps.hide();

        var tabId = $(this).attr('id');

        switch (tabId) {
          case 'renton':
            rentonBox.fadeIn();
                break;
          case 'southcenter':
            southcenterBox.fadeIn();
                break;
          case 'seattle':
            seattleBox.fadeIn();
                break;
        }
      });

      $('.form_reopen').hide();

      $('.close_circle').click(function(e){
        e.preventDefault();
        $('.patient_form').hide();
        $('.form_reopen').show();
      });

      $('.form_reopen a').click(function(e){
        e.preventDefault();
        $('.patient_form').show();
        $('.form_reopen').hide();
      });

      // $('.welcome__slider').slick({
      //   slidesToShow: 1,
      //   slidesToScroll: 1,
      //   arrows: false,
      //   dots: false,
      //   autoplay: true,
      //   autoplaySpeed: 5000
      // });
      
      // $('#slides').slidesjs({
      //   width: 1600,
      //   height: 650,
      //   navigation: {
      //     active: false
      //   },
      //   play: {
      //     auto: true
      //   }
      // });

      

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
